import React, { useEffect } from "react";
import PageHeader from "../components/PageHeader";
import InnerHeadr from "../components/InnerHeader";

const pageDetails = {
  head: {
    upper: "Projects We've",
    lower: "DELIVERED",
  },
  bottom: {
    normal: "WE'VE DONE IT BEFORE",
    italic: "WE CAN DO IT AGAIN",
  },
};

export default function CaseStudies() {
  return (
    <div className="w-screen relative z-20 min-h-screen flex flex-col items-start justify-center">
      <PageHeader title="Case Studies" />
      <InnerHeadr head={pageDetails.head} bottom={pageDetails.bottom} />
    </div>
  );
}
