import React from "react";

export default function InnerHeadr({ head, bottom }) {
  return (
    <div className="w-screen relative flex flex-col items-start justify-center h-screen ">
      <div className="max-w-7xl container px-8 lg:text-9xl text-5xl helv font-semibold uppercase tracking-tighter mx-auto">
        {head.upper}
        <br /> <span className="italic text-primary-100 font-light capitalize">{head.lower}</span>
      </div>
      <div className="max-w-7xl container px-8 mx-auto">
        <div className=" mt-32">
          <div className="helv uppercase flex flex-c">
            {bottom.normal} <br />
            {bottom.italic}
          </div>
        </div>
      </div>
    </div>
  );
}
