import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../layout/Layout";

export default function PageHeader({ title }) {
  const theme = useContext(ThemeContext);

  const scaleHeader = () => {
    console.log("CALLING");
    let scalable = document.querySelectorAll(".scale--js");
    let margin = 10;
    for (let i = 0; i < scalable.length; i++) {
      let scalableContainer = scalable[i].parentNode;
      scalable[i].style.transform = "scale(1)";
      let scalableContainerWidth = scalableContainer.offsetWidth - margin;
      let scalableWidth = scalable[i].offsetWidth;
      scalable[i].style.transform = "scale(" + scalableContainerWidth / scalableWidth + ")";
      scalableContainer.style.height = scalable[i].getBoundingClientRect().height + "px";
    }
  };

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  var myScaleFunction = debounce(function () {
    scaleHeader();
  }, 250);

  useEffect(() => {
    myScaleFunction();
    window.addEventListener("resize", myScaleFunction);
  }, []);

  return (
    <>
      <div className="w-screen h-screen absolute inset-0 flex items-end justify-start mx-auto">
        <div className="containerr">
          <div className="scale__container--js">
            <h2 className={`scale--js radio  select-none ${theme === "dark" ? "lg:text-orange-50 text-orange-200 opacity-10 " : "text-orange-50 opacity-40 "}`}>{title}</h2>
          </div>
        </div>
      </div>
    </>
  );
}
